<!-- <button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button> -->
<div class="m-auto w-fit">
  <img
    *ngIf="this.data.caller !== '_STEPPER'"
    appImg
    src="assets/gif/mobile-login-animate.svg"
    class="w-64"
    alt="login animation" />
</div>

<div
  *ngIf="this.data.caller === '_STEPPER' && !this.data.params"
  class="text-center w-full mb-5 font-medium bg-kimbo-blue bg-opacity-5 rounded-md p-2 text-kimbo-blue">
  {{ 'common.have-to-login-msg' | translate }}
</div>

<div
  *ngIf="
    this.data.caller === '_STEPPER' && this.data.params && this.data.params.key
  "
  class="text-center w-full mb-5">
  {{ 'common.wrong-invitation-account-message' | translate }}
</div>

<app-google-login></app-google-login>

<div
  *ngIf="this.data.caller === '_STEPPER' || this.data.caller === '_SIGN_UP'"
  class="w-full inline-flex items-center justify-center relative mx-auto mt-2">
  <hr class="w-64 h-px my-8 bg-gray-200 border-0" />
  <span class="absolute px-3 font-medium -translate-x-1/2 bg-white left-1/2">
    {{ 'hcp-home.or' | translate }}
  </span>
</div>

<form class="{{ this.data.caller !== '_STEPPER' ? 'mt-4' : '' }}">
  <app-form-field
    *ngIf="!isPhoneNumber"
    [label]="
      (identifier.length === 0
        ? 'forms.phone-label'
        : 'forms.email-placeholder'
      ) | translate
    "
    [error]="fromError">
    <input
      (input)="setIdentifier($event)"
      [(ngModel)]="identifier"
      [ngModelOptions]="{ standalone: true }"
      class="
 {{ input_cls.common }}
{{ invalid ? input_cls.invalid : '' }}
{{ disabled ? input_cls.disabled : '' }}"
      type="text"
      name="identifier"
      id="tel"
      [placeholder]="'forms.phone-number-placeholder' | translate" />
  </app-form-field>

  <div *ngIf="isPhoneNumber" class="relative w-full mt-6">
    <app-phone-number-dropdown
      class="w-full"
      tabindex="1"
      (triggerEvent)="handlePhoneEvent($event)"
      [country]="country"
      [options]="countryListToOptionObject()"
      [tel]="_identifier"
      [withWhatapp]="false"
      [placeholder]="'forms.phone-placeholder' | translate"
      [name]="
        'kimbo-create-contact.phone-label' | translate
      "></app-phone-number-dropdown>
  </div>
  <app-form-error *ngIf="error" class="block w-full my-4 text-center">{{
    error
  }}</app-form-error>
  <div class="mt-6">
    <button
      (click)="fastLogin()"
      [disabled]="isLoading"
      type="submit"
      style="box-shadow: rgba(0, 168, 147, 0.36) 0rem 0.625rem 1.875rem"
      class="w-full px-11 text-lg text-center tracking-wider h-14 disabled:bg-opacity-20 flex justify-center items-center bg-transparent rounded-full font-semibold bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding text-white cursor-pointer">
      <app-spinner
        *ngIf="isLoading"
        class="inline-block ml-4 h-6 w-6 filter-white"></app-spinner>
      <span *ngIf="!isLoading">{{
        (data.caller === '_STEPPER' ? 'common.continue-label' : 'sign-up.h1')
          | translate
      }}</span>
    </button>
  </div>
</form>
