<div
  class="{{ isVisible ? '' : 'hidden' }} inset-0 fixed z-navigation-backdrop
bg-blue opacity-50"
  (click)="hide()"></div>

<div
  class="{{
    isVisible ? 'right-0 opacity-100' : '-right-full opacity-0'
  }} flex-shrink-0 rounded-tl-[3rem] glass-morphism-blue pb-12 fixed top-0 w-72 z-navigation h-screen md:h-auto md:w-32 overflow-y-scroll">
  <div class="absolute hidden h-full bg-blue right-full w-max md:block"></div>
  <app-square-button
    classNames="w-8 h-8 mt-4 ml-6 md:hidden absolute right-4"
    (click)="hide()"
    size="medium"
    theme="light-blue">
    <div
      class="w-6 h-6 text-white stroke-current"
      aria-label="close icon"
      [inlineSVG]="'assets/svg/close-s.svg'"></div>
  </app-square-button>

  <div class="overflow-auto" style="max-height: calc(100% - 2rem)">
    <div class="flex flex-col items-center mt-12 text-center text-white">
      <app-avatar
        (click)="openProfilDialog()"
        class="cursor-pointer"
        [classNames]="'w-24 h-24'"
        [withFrame]="true"
        [withPreview]="false"
        caller="_PROFILE"
        [src]="_user.image_profil | imageToUrl"></app-avatar>
      <div class="w-fit mt-[17px] text-lg text-center">
        <div
          *ngIf="!sessionService.isGuest()"
          class="font-bold flex gap-2 justify-center">
          {{
            _user.data && _user!.data.lastName && _user!.data.firstName
              ? _user!.data.firstName + ' ' + _user!.data.lastName
              : ''
          }}
        </div>
        <div
          *ngIf="sessionService.isGuest()"
          class="font-bold flex flex-col items-center justify-center gap-3">
          <span>{{ 'common.guest-label' | translate }}</span>
          <app-language-select caller="C_WITH_BG"></app-language-select>
        </div>
        <button
          *ngIf="!sessionService.isGuest()"
          (click)="openProfilDialog()"
          class="text-base font-normal mx-auto mt-2 bg-black bg-opacity-20 transition-all w-fit px-3 py-2 hover:text-white rounded-full flex items-center justify-center cursor-pointer">
          {{ 'corporate-dashboard.campain-edit-profil' | translate }}
          <img
            appImg
            class="images ml-2"
            src="assets/images/stylo.png"
            alt="stylo" />
        </button>
      </div>
    </div>
    <ul class="relative pt-4 text-xs text-white z-base md:pt-12">
      <!--li *ngIf="sessionService.isGuest()">
        <a
          (click)="openSignUpModal()"
          class="w-full px-11 text-base text-center tracking-wider h-14 flex justify-center items-center font-semibold glass-morphism-blue text-white cursor-pointer">
          {{ 'sign-in.no-account-text-action' | translate }}
        </a>
      </li-->
      <li *ngIf="sessionService.isGuest()">
        <button
          (click)="openLoginModal('_SIGN_IN')"
          class="w-full px-11 text-base text-center tracking-wider h-14 flex justify-center items-center bg-transparent font-semibold bg-kimbo-gradient bg-no-repeat bg-origin-padding bg-clip-padding text-white cursor-pointer">
          {{ 'sign-in.2fa-submit-button' | translate }}
        </button>
      </li>
      <li *ngIf="!sessionService.isGuest()">
        <app-menu-role-select
          *ngIf="filterOptions"
          (triggerEvent)="handleEvent($event)"></app-menu-role-select>
      </li>

      <li *ngIf="!isGuest">
        <app-health-credits-panel
          *ngIf="currentRoleType !== groupType.Admin"
          (click)="hide()"
          [variant]="'_MENU'"></app-health-credits-panel>
      </li>

      <button
        *ngIf="currentRoleType === groupType.Kimbo"
        (click)="openDiscountModal()">
        <a
          class="focus:outline-none focus:shadow-inner-lighter-blue w-full cursor-pointer focus:text-blue flex justify-start px-6 py-4 items-center gap-1 relative">
          <mat-icon color="white">card_giftcard</mat-icon>
          <span class="tab tab-home ml-1 block text-xs">{{
            'common.activate-gift-card-label' | translate
          }}</span>
        </a>
      </button>

      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Kimbo && !isGuest"
        [iconPath]="'assets/svg/patients.svg'"
        [iconLabel]="'My treatments'"
        [location]="'/kimbo/my-treatments'">
        {{ 'kimbo-home.treatments-title' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Benefit"
        [iconPath]="'assets/svg/general-medicine-white.svg'"
        [iconLabel]="'Healthcare providers'"
        [location]="'/benefit/healthcare-providers'">
        {{ 'benefit-layout.healthcare-providers-label' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Kimbo && !isGuest"
        [iconPath]="'assets/svg/statistics.svg'"
        [iconLabel]="'My Impact'"
        [location]="'/kimbo/my-impact'">
        {{ 'kimbo-layout.nav-my-impact-link' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Hcp && !isGuest"
        [iconPath]="'assets/svg/statistics.svg'"
        [iconLabel]="'Dashboard icon'"
        [location]="'/hcp/dashboard-hcp'">
        {{ 'hcp-layout.nav-m-dashboard' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/patients.svg'"
        [iconLabel]="'healthcare-providers'"
        [location]="'/admin/healthcare-providers'">
        {{ 'admin-layout.nav_health_providers' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/modules.svg'"
        [iconLabel]="'healthcare-package'"
        [location]="'/admin/healthcare-package'">
        {{ 'admin-layout.nav-healthcare-modules-link' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/money-balance.svg'"
        [iconLabel]="'currencies'"
        [location]="'/admin/currencies'">
        {{ 'admin-layout.nav-exchange-rates-link' | translate }}
      </app-layout-nav-link>

      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/patients.svg'"
        [iconLabel]="'patients'"
        [location]="'/admin/buyedpackages'">
        {{ 'admin-layout.buyedpackage-label' | translate }}
      </app-layout-nav-link>

      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/people-white.svg'"
        [iconLabel]="'people-white'"
        [location]="'/admin/campaigns'">
        {{ 'corporate-campaign.campaign' | translate }}
      </app-layout-nav-link>

      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/wallet-menu.svg'"
        [iconLabel]="'wallet-menu'"
        [location]="'/admin/invoice'">
        {{ 'admin-layout.nav-invoices-link' | translate }}
      </app-layout-nav-link>
      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/images/discount.png'"
        [iconLabel]="'discount'"
        [location]="'/admin/discount'">
        {{ 'admin-layout.nav-discount-cards-link' | translate }}
      </app-layout-nav-link>

      <app-layout-nav-link
        (click)="hide()"
        *ngIf="currentRoleType === groupType.Admin"
        [iconPath]="'assets/svg/setting.svg'"
        [iconLabel]="'settings'"
        [location]="'/admin/settings'">
        {{ 'admin-settings.page-heading' | translate }}
      </app-layout-nav-link>

      <li *ngIf="currentRoleType !== groupType.Admin">
        <a
          (click)="openSupportModal()"
          class="focus:outline-none focus:shadow-inner-lighter-blue w-full cursor-pointer focus:text-blue flex justify-start px-6 py-4 items-center gap-1 relative">
          <img appImg class="w-6 h-6" [src]="'assets/svg/faq.svg'" alt="faq" />
          <span class="tab tab-home ml-2 block text-xs">{{
            'kimbo-layout.nav-support-link' | translate
          }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div
    class="w-full flex justify-center items-center flex-col text-white py-4 z-notifier glass-morphism-blue absolute bottom-0">
    <div
      *ngIf="!sessionService.isGuest()"
      class="w-full flex justify-center items-center text-white">
      <app-image-render
        img="assets/svg/out.svg"
        alt="out icon"
        classNames="w-[17px] block relative filter-white"></app-image-render>
      <span
        class="text-[16px] font-medium block ml-3 cursor-pointer text-white"
        (click)="logout()"
        >{{ 'common.logout-button' | translate }}</span
      >
    </div>
    <div class="text-white text-xs mt-3">
      {{ 'v' + appVersion }}
    </div>
  </div>
</div>
