const basePath = 'https://api.sandbox.kimbocare.dev';
const kimboCampaignFees = 0.12;
const deeplApiKey = 'c33fc85c-93ff-4d12-a52c-130787e20571:fx';
export const environment = {
  production: true,
  serviceWorker: true,
  vapid_key: "BF4F_RSMfsKwxbY1DlZ49I8DayHS-tl4v9fQgz_KIUs4xZyzzFA5iRcEtk08GPkc0EvGDKsGy9mEG0ftdKIHKG8",
  google_client_id: "100180207220-7po6nvcajccccoalkr61k3me4uk2s8r1.apps.googleusercontent.com",
  map_API_key: "AIzaSyAHqT1zAjqeka7Ha1mb33HL0lFM5B2IKmc",
  appVersion: require('../../package.json').version,
  basePath,
  badge: false,
  name: 'production',
  deeplApiKey,
  sentry: {
    tracingOrigins: ['https://app.kimbocare.com'],
  },
  recaptcha: {
    siteKey: '6LcRPTYmAAAAAArMl6NN16N_kXQaVOT7EGgczl5d',
  },
  kimboCampaignFees,
  ssk: 'we-sv-rtw--eüd-§$-fd',
  ssv: 'öer:gerv:vjdf+*dfe#e-ee,'
};