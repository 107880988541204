import { Component, Input, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { TranslateService } from '@ngx-translate/core';
import { IHealthcareProvider } from 'src/app/core/models/HealthcareProvider';
import { IGoogleMapHCP } from 'src/app/core/models/scrapping';
import { ImageProfil } from 'src/app/core/models/ImageProfil';
import { UseCaseHcpService } from 'src/app/core/rest-services/rest-services';
import { ServicesService } from 'src/app/core/services/services.service';
import { HealthcareProviderComponent } from 'src/app/pages/shared-pages/healthcare-providers/components/healthcare-provider/healthcare-provider.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @Input() KimboHcps: IHealthcareProvider[] = [];
  @Input() googleHcps: IGoogleMapHCP[] = [];
  @Input() selectedHCPType!: string; // KIMBO || OTHER
  @Input() selectedHCPForMap!: IHealthcareProvider;
  basePath = environment.basePath;
  map!: google.maps.Map;
  loader = new Loader({
    apiKey: environment.map_API_key,
    version: 'weekly',
  });

  location = { lat: 37.43238031167444, lng: -122.16795397128632 };
  openInfoWindow = false;
  mapOptions = {
    center: this.location,
    zoom: 10,
    minZoom: 5, // Set minimum zoom level
    mapTypeControl: false,
    mapId: '2b5058986a8f1758',
  };
  kimboMarkers: any[] = [];
  googleMarkers: any[] = [];

  constructor(
    private services: ServicesService,
    private usecaseHcpService: UseCaseHcpService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.KimboHcps.length > 0) {
      this.location.lat = Number(this.KimboHcps[0].adresse.latitude);
      this.location.lng = Number(this.KimboHcps[0].adresse.longitude);
    } else if (this.googleHcps.length > 0) {
      this.location.lat = this.googleHcps[0].adresse.latitude;
      this.location.lng = this.googleHcps[0].adresse.longitude;
    }
    this.mapOptions.center = this.location;
    this.initMap();
  }

  async initMap() {
    this.loader
      .importLibrary('maps')
      .then(({ Map }) => {
        this.map = new Map(
          document.getElementById('map') as HTMLElement,
          this.mapOptions
        );
        if (this.selectedHCPType && this.selectedHCPType === 'KIMBO')
          this.createKimboMarkers();
        else this.createGoogleMarkers();
      })
      .catch(() => {});
  }

  private async createKimboMarkers() {
    const bounds = new google.maps.LatLngBounds();
    const { AdvancedMarkerElement } = (await this.loader.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const { PinElement } = (await this.loader.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const { InfoWindow } = (await this.loader.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;

    const infoWindow = new InfoWindow();

    this.KimboHcps.map(provider => {
      if (provider.adresse.latitude && provider.adresse.longitude) {
        const position = {
          lat: parseFloat(provider.adresse.latitude),
          lng: parseFloat(provider.adresse.longitude),
        };

        bounds.extend(new google.maps.LatLng(position.lat, position.lng));

        provider.images = this.setProviderImage(provider);

        const glyphImg = document.createElement('img');
        glyphImg.src = 'assets/icons/favicon-32x32.png';
        const glyphImgPinElement = new PinElement({
          glyph: glyphImg,
          borderColor: !this.selectedHCPForMap
            ? '#1e68c2'
            : this.selectedHCPForMap &&
              this.selectedHCPForMap.adresse.latitude ===
                provider.adresse.latitude &&
              this.selectedHCPForMap.adresse.longitude ===
                provider.adresse.longitude
            ? '#fff'
            : '#1e68c2',
          background: !this.selectedHCPForMap
            ? '#fff'
            : this.selectedHCPForMap &&
              this.selectedHCPForMap.adresse.latitude ===
                provider.adresse.latitude &&
              this.selectedHCPForMap.adresse.longitude ===
                provider.adresse.longitude
            ? '#1e68c2'
            : '#fff',
          scale: 2,
        });

        const markers = new AdvancedMarkerElement({
          title: provider.name,
          position,
          map: this.map,
          content: glyphImgPinElement.element,
          gmpClickable: true,
        });

        const content = `
      <div id="htcp-map-marker-modal" class="w-fit flex flex-row max-w-full max-h-[126px] cursor-pointer">
        <img
          class="inline-block rounded-lg bg-transparent max-w-[126px] min-w-[126px] max-h-[126px] min-h-[126px] object-cover"
          src="${this.basePath}/${provider.image_profil.path}"
          alt="Profile Image"
        />
        <div
          class="inline-block font-semibold max-h-[126px] min-h-[126px] max-w-[201px] min-w-[201px] rounded-tr-2xl rounded-br-2xl pt-[12px] pb-[12px] pl-[16px] pr-[16px]">
          <div>
            <span class="text-base block">
              ${
                provider.name ||
                `${provider.adresse.city} - ${provider.adresse.country}`
              }
            </span>
          </div>
          <div>
            <span style="color: #edca0d" class="text-base inline-block">${
              provider.rating ? '★ ' + provider.rating : ''
            }</span>
          </div>
          <div>
            <span class="text-sm text-dark-gray">
              ${provider.adresse.country} · ${provider.adresse.city}
            </span>
          </div>
          <div>
            <span class="text-sm text-dark-gray">
              ${'Type: ' + provider.type || ''}
            </span>
          </div>
        </div>
      </div>
    `;
        markers.addListener('click', () => {
          if (infoWindow.isOpen) infoWindow.close();
          else {
            infoWindow.setContent(content);
            infoWindow.setHeaderDisabled(true);
            infoWindow.open(this.map, markers);
            google.maps.event.addListener(infoWindow, 'domready', () => {
              const infoWindowContent = document.querySelector('.gm-style-iw');
              if (infoWindowContent) {
                infoWindowContent.addEventListener('click', () => {
                  infoWindow.close();
                  this.services.modalService.closeAllModals();
                  this.openHcpDetails(provider);
                });
              }
            });
          }
        });
        this.kimboMarkers.push(markers);
        this.map.fitBounds(bounds);
      }
    });
  }

  setProviderImage(provider: IHealthcareProvider): ImageProfil[] {
    if (provider?.images?.length === 0) return [provider?.image_profil];
    return provider?.images;
  }

  openHcpDetails(hcp: IHealthcareProvider) {
    this.usecaseHcpService.count_view_hcp(hcp.id).subscribe({});
    this.services.modalService.openModal(HealthcareProviderComponent, {
      width: '1000px',
      height: 'auto',
      data: {
        healthcareProvider: hcp,
        caller: '_COMMON',
      },
    });
  }

  private async createGoogleMarkers() {
    const bounds = new google.maps.LatLngBounds();
    const { AdvancedMarkerElement } = (await this.loader.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;
    const { PinElement } = (await this.loader.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const { InfoWindow } = (await this.loader.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;

    const infoWindow = new InfoWindow();

    this.googleHcps?.map((provider: IGoogleMapHCP) => {
      const position = {
        lat: provider?.adresse?.latitude,
        lng: provider?.adresse?.longitude,
      };

      bounds.extend(new google.maps.LatLng(position.lat, position.lng));

      const pinElement = new PinElement({
        borderColor: '#F5F5F5',
        background: '#276dbc',
        glyphColor: '#6391c6',
        scale: 1.5,
      });

      const markers = new AdvancedMarkerElement({
        position,
        map: this.map,
        content: pinElement.element,
        gmpClickable: false,
        title: provider.name + ' ' + provider.adresse.name,
      });

      const content = `<div id="htcp-map-marker-modal" class="flex flex-row max-w-[327px] max-h-[126px]">
        <div
          class="inline-block max-h-[126px] max-w-[201px] p-2">
          <div class='flex flex-col gap-1 justify-between font-semibold'>
            <span class="text-base inline-block">
              ${provider.name ? provider.name : ''}
            </span>
            <span class="text-base inline-block text-yellow-500">${
              provider.rating ? '★ ' + provider.rating : ''
            }</span>
          </div>
          <div>
            <span class="text-sm">
              ${provider.adresse.name ? provider.adresse.name : ''}
            </span>
          </div>
           <div>
            <span class="text-xs text-kimbo-blue font-semibold">
              ${this.translateService.instant(
                'kimbo-layout.not-yet-on-kimbocare'
              )}
            </span>
          </div>
        </div>
      </div>`;

      markers.addListener('click', () => {
        if (infoWindow.isOpen) infoWindow.close();
        else {
          infoWindow.setHeaderDisabled(true);
          infoWindow.setContent(content);
          infoWindow.open(this.map, markers);
          google.maps.event.addListener(infoWindow, 'domready', () => {
            const infoWindowContent = document.querySelector('.gm-style-iw');
            if (infoWindowContent) {
              infoWindowContent.addEventListener('click', () => {
                infoWindow.close();
              });
            }
          });
        }
      });
      this.googleMarkers.push(markers);
      this.map.fitBounds(bounds);
    });
  }

  closeMapPannel(): void {
    this.services.eventService.publish('show-conversation:logs:hide');
  }
}
